import type { ImagesSettings } from './types/PartnerSettings';
import defaultSettings from './data/defaultPartnerSettings';
import allCountrySettings from './data/countrySettings';
import partnerSettings from './data/partnerSettings';

const getPartnerTheme = (
  partner: string,
  themeName: 'alpha' | 'bravo' = 'alpha',
) => {
  const themeSettings = partnerSettings[partner]?.theme;

  const theme =
    themeName === 'alpha'
      ? defaultSettings.theme.alpha
      : defaultSettings.theme.bravo;

  return { ...theme, ...themeSettings };
};

const getPartnerImages = (partner: string): ImagesSettings => {
  const settings: any = partnerSettings[partner]?.images || {};

  return { ...defaultSettings.images, ...settings };
};

const getPartnerSettings = (partner: string) => {
  const allCountrySettingsClone = structuredClone(allCountrySettings);
  const defaultSettingsClone = structuredClone(defaultSettings);
  const partnerSettingsClone = structuredClone(partnerSettings[partner]);
  const countrySettings =
    allCountrySettingsClone[
      partnerSettingsClone?.country || defaultSettings.country || 'GB'
    ];

  const aceSettings = Object.assign(
    {},
    defaultSettingsClone.activeCustomerSettings,
    countrySettings?.activeCustomerSettings,
    partnerSettingsClone?.activeCustomerSettings,
  );

  // theme is a deeply nested object therefore need to be set manually.
  if (partnerSettingsClone?.theme) {
    partnerSettingsClone.theme = getPartnerTheme(partner);
  }

  if (partnerSettingsClone?.images) {
    partnerSettingsClone.images = getPartnerImages(partner);
  }

  return {
    ...defaultSettingsClone,
    ...countrySettings,
    ...partnerSettingsClone,
    activeCustomerSettings: aceSettings,
  };
};

const buildPartnerThemeCSSProperties = (
  partner?: string,
  themeName: 'alpha' | 'bravo' = 'alpha',
): string => {
  const partnerTheme = getPartnerTheme(partner || '', themeName);

  let CSSString = ':root{';
  Object.keys(partnerTheme).forEach((key) => {
    CSSString += `--${key}:${partnerTheme[key]};`;
  });
  CSSString += '};';

  return CSSString;
};

const getPartners = (): string[] => {
  return Object.keys(partnerSettings);
};

const getPartnerByPartnerId = (partnerCode: string) => {
  const settingsForPartnerKey = Object.entries(partnerSettings).find(
    ([_, value]) => value.id === partnerCode
  )?.[0];

  return settingsForPartnerKey;
}

export {
  getPartnerTheme,
  getPartners,
  getPartnerSettings,
  buildPartnerThemeCSSProperties,
  getPartnerByPartnerId,
};
